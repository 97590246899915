/* You can add global styles to this file, and also import other style files */

body {
    /* Fonts */
    --font-text: "Roboto", "Arial";
    --font-title: "Montserrat", "Arial";

    /* Colors */
    --color-primary: rgb(56, 56, 56);
    --color-primary-dark: rgb(37, 37, 37);
    --color-dark-transparent: rgba(37, 37, 37, 0.6);
    --color-primary-light: #ffffff;
    --color-secondary: #ffbd59;
    --color-secondary-dark: #fe9e37;
    --color-secondary-light: #ffce84;
    --color-secondary-light2: #ffdfaf;
    --color-clear: #f1f2fb;
    --color-highlight: #ffe2b7;
    --color-highlight2: #fffaed;

    background-color: var(--color-primary);

    margin: 0;
    padding: 0;
}